.how_it_works {
  position: relative;
  min-height: 100vh; /* Ensure full-screen height */
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.video_section {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1; /* Place video behind content */
  overflow: hidden;
}

.video_section::after {
  content: "";
  position: absolute;
  bottom: -3px;
  left: 0;
  width: 100%;
  height: 7%;
  background: linear-gradient(to bottom, rgb(0 0 0 / 67%) 0%, rgb(0 0 0) 100%);
  pointer-events: none;
  backdrop-filter: blur(1px);
  z-index: 1;
}

.how_it_works.animate .video_section {
  transform: scale(1.1); /* Zoom in */
  transition: transform 2s ease; /* Slower zoom-in animation */
}

.background_video {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Cover the entire section */
}

.text_section {
  position: relative;
  z-index: 2; /* Layer above the video */
  padding: 4rem;
  text-align: center;
  color: #fff;
}

.main_heading {
  font-family: Poppins, sans-serif;
  font-size: 64px;
  font-weight: 600;
  line-height: 96px;
}

.sub_heading {
  color: #ffffff;
  margin-bottom: 70px;
  font-family: Poppins, sans-serif;
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
}

.main_heading,
.sub_heading {
  opacity: 0; /* Hidden initially */
  transform: translateY(50px); /* Start from below */
  transition: opacity 1s ease, transform 1s ease; /* Smooth transition */
}

.main_heading.visible,
.sub_heading.visible {
  opacity: 1; /* Fade in */
  transform: translateY(0); /* Move to original position */
}

.steps_section {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 1rem;
}

.step_item {
  flex: 1 1 calc(33.33% - 2rem);
  display: flex; /* To enable alignment */
  background: #ffffff40;
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px);
  border-radius: 40px;
  justify-content: center;
  align-items: center;
  text-align: center;
  opacity: 0; /* Hidden initially */
  transform: translateY(50px); /* Slide from bottom */
  transition: opacity 0.8s ease, transform 0.8s ease; /* Common transition properties */
}

.step_item.visible {
  opacity: 1; /* Fade in */
  transform: translateY(0); /* Move to the original position */
}

/* Adding delay for each step */
.step_item:nth-child(1).visible {
  transition-delay: 0s;
}

.step_item:nth-child(2).visible {
  transition-delay: 0.2s;
}

.step_item:nth-child(3).visible {
  transition-delay: 0.4s;
}

.step_item:nth-child(4).visible {
  transition-delay: 0.6s;
}

.step_item:nth-child(5).visible {
  transition-delay: 0.8s;
}

.step_item:nth-child(6).visible {
  transition-delay: 1s;
}

.step_item:nth-child(7).visible {
  transition-delay: 1.2s;
}

.step_item:nth-child(8).visible {
  transition-delay: 1.4s;
}

.step_box {
  /* background: #ffffff40;
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px);
  border-radius: 40px; */
  padding: 5rem 1rem;
  min-height: 240px;
  width: 100%; 
  box-sizing: border-box;
}

.step_title {
  font-family: Poppins;
  font-size: 32px;
  font-weight: 600;
  line-height: 48px;
}

.step_description {
  font-family: Poppins;
  font-size: 14px;
  font-weight: 500;
}

.how_it_works_image {
  border-radius: 2rem;
}

@media (max-width: 768px) {
  .steps_section {
    flex-direction: column;
  }

  .step_item {
    flex: 1 1 100%;
  }
}
