.video-section {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  background: black;
  height: 100vh; /* Ensures the section takes up the full height of the viewport */
  opacity: 0;
  transform: translateY(30px); /* Start from below */
  transition: opacity 0.6s ease-out, transform 0.6s ease-out;
}

.video-section.animate {
  opacity: 1;
  transform: translateY(0); /* Move to original position */
}

.video-container {
  width: 70%; /* Set to 40% or 50% of the screen width */
  max-width: 800px; /* Optional: max-width to ensure it doesn't get too wide on large screens */
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-top: 5rem;
  margin-bottom: 5rem;
}

.video-container iframe {
  width: 100%;
  height: 450px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Mobile and Tablet Media Query */
@media (max-width: 768px) {
  .video-section {
    height: auto; /* Let the height adjust automatically based on content */
    padding: 10px; /* Adjust padding for smaller screens */
    background-size: contain; /* Ensure background image fits on smaller screens */
    flex-direction: column; /* Stack content vertically on smaller screens */
  }

  .video-section h2 {
    font-size: 22px; /* Reduce font size for mobile */
    margin-bottom: 15px;
  }

  .video-container {
    width: 100%; /* Make the video container full width */
    margin-top: 2rem; /* Adjust the top margin */
  }

  .video-container iframe {
    height: 200px; /* Reduce the iframe height for mobile */
  }
}

@media (min-width: 769px) and (max-width: 1024px) {
  .video-section {
    height: auto; /* Let height adjust for content on tablets */
    padding: 15px; /* Adjust padding */
    background-size: cover; /* Keep the background image cover behavior */
  }

  .video-section h2 {
    font-size: 24px; /* Slightly smaller font for tablets */
    margin-bottom: 18px;
  }

  .video-container {
    width: 90%; /* Slightly wider for tablet view */
    margin-top: 5rem; /* Adjust the top margin */
  }

  .video-container iframe {
    height: 350px; /* Adjust iframe height for tablet view */
  }
}
