.block32 {
    width: 95%;
    background: rgba(255, 255, 255, 0.15);
    box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
    backdrop-filter: blur(2px);
    -webkit-backdrop-filter: blur(2px);
    border: 1px solid rgba(255, 255, 255, 0.18);
    border-radius: 1rem;
    margin: -5rem 0rem 0rem 2rem;
    position: absolute;
    z-index: 999;
    opacity: 0; /* Initially hidden */
    transform: translateY(50px); /* Initial position */
    transition: opacity 0.8s ease, transform 0.8s ease; /* Smooth animation */
}
 
.block32.animate {
    opacity: 1; /* Fade in */
    transform: translateY(0); /* Move to original position */
}
 
.columns-container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 20px;
}
 
.column {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 20px;
}
 
.iconsimg {
    width: 20%;
}
 
.column-heading {
    font-family: Poppins;
    font-size: 18px;
    font-weight: 600;
    line-height: 36px;
    text-align: left;
    color: white;
    margin-top: 1rem;
}
 
/* Responsive Design */
@media (max-width: 1200px) {
    .columns-container {
        grid-template-columns: repeat(2, 1fr);
    }
}
 
@media (max-width: 768px) {
    .columns-container {
        grid-template-columns: 1fr;
    }
}
 
 