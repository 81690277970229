/* Base Styling */
.top_features {
    color: #fff;
    background-color: #000;
    text-align: center;
    padding: 0rem 1rem;
    width: 100%;  /* Ensures the section spans the full container */
    box-sizing: border-box;  /* Ensures padding is included in the width calculation */
}

.top_features_text_section {
    padding: 5rem 0 3rem;
}

.top_features_main_heading {
    margin-bottom: 2rem;
    font-family: Poppins, sans-serif;
    font-size: 48px;
    font-weight: 600;
    line-height: 1.5;
}

/* Initial State */
.top_features_step_box {
    opacity: 0; /* Initially hidden */
    transform: translateY(50px); /* Move downwards */
    transition: opacity 0.8s ease, transform 0.8s ease; /* Smooth animation */
}

/* Animated State */
.top_features_step_box.animate {
    opacity: 1; /* Fade in */
    transform: translateY(0); /* Move to original position */
}

/* Initial State for Heading */
.top_features_main_heading {
    opacity: 0; /* Initially hidden */
    transform: translateY(-50px); /* Move upwards */
    transition: opacity 0.8s ease, transform 0.8s ease; /* Smooth animation */
}

/* Animated State for Heading */
.top_features_main_heading.animate {
    opacity: 1; /* Fade in */
    transform: translateY(0); /* Move to original position */
}


/* Grid Styling */
.top_features_grid {
    display: flex;
    gap: 2rem;
    justify-content: center;
    align-items: center;
    margin-bottom: 2rem;
    flex-wrap: wrap; /* Allows items to wrap when necessary */
    width: 100%;  /* Ensures the grid spans the entire container */
    box-sizing: border-box;  /* Ensures padding and gap are correctly calculated */
}

/* Specific Row Styling */
.first_row {
    flex-wrap: wrap; /* Ensures responsiveness */
}

.second_row {
    display: flex;
    gap: 2rem;
    justify-content: center;
}

/* Feature Box Styling */
.top_features_step_box {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    flex: 1 1 250px;  /* Ensures the boxes take equal space, with a minimum width of 250px */
    padding: 1rem;
}

.top_features_iconsimg {
    width: 60px;
    height: 60px;
    margin-bottom: 1rem;
}

.top_features_step_title {
    font-family: Poppins, sans-serif;
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 0.5rem;
}

.top_features_step_description {
    font-family: Poppins, sans-serif;
    font-size: 14px;
    line-height: 1.6;
    max-width: 280px;
    margin: 0 auto;
}

/* Responsive Design */
@media (max-width: 768px) {
    .top_features_grid {
        flex-direction: column;
        gap: 1.5rem; /* Adjust gap for smaller screens */
    }
}

@media (max-width: 480px) {
    .top_features_main_heading {
        font-size: 36px; /* Adjust heading size for smaller screens */
    }
    .top_features_step_title {
        font-size: 18px; /* Adjust title size */
    }
    .top_features_step_description {
        font-size: 13px; /* Adjust description size */
    }
}
