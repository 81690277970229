
.footer {
 
  background-color: #000000; /* Black background */
 
  color: #ffffff; /* White text */
 
  padding: 2rem 0; /* Padding for spacing */
 
  text-align: center; /* Centered content */
 
  position: relative;
 
}
 
.footer_flags {
  display: flex;
  gap: 3rem;
  justify-content: center;
  align-items: center;
  margin: 2rem 0rem 4rem 0rem;
}
 
.flag_names {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 1rem;
}
 
.footer__divider {
  width: 1px;
  background-color: white;
  height: 8rem;
}
 
.footer__address {
  text-align: center;
  display: flex;
  flex-direction: column; /* Ensures flag and text stack vertically */
  align-items: center; /* Centers content horizontally */
  gap: 1rem; /* Space between flag and text */
  color: white; /* Text color */
}
 
.footer__flag {
  width: 50px; /* Flag size */
  height: auto;
  margin-bottom: 10px; /* Space below the flag */
}
 
.footer__address-text {
  font-size: 14px;
  line-height: 1.6;
}
 
.footer__link {
 
  font-family: Poppins;
  font-size: 20px;
  font-weight: 500;
  line-height: 60px;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;    
  text-decoration: none; /* Remove underline */
  transition: color 0.3s ease;
 
}
 
.footer__link:hover {
 
  color: #3498db; /* Light blue on hover */
 
}
 
.footer__copy {
 
  font-size: 0.875rem; /* Small text for copyright */
 
  opacity: 0.8; /* Slightly faded text */
 
}
 
.footer__back-to-top {
 
  display: inline-flex;
 
  align-items: center;
 
  justify-content: center;
 
  color: #ffffff; /* White text */
 
  text-decoration: none; /* Remove underline */
 
  font-size: 1rem; /* Font size for the text */
 
  background-color: #000000; /* Black background */
 
  width: 3rem; /* Button width */
 
  height: 3rem; /* Button height */
 
  position: absolute;
 
  bottom: 2rem; /* Position from bottom */
 
  right: 2rem; /* Position from right */
 
  transition: transform 0.3s ease, background-color 0.3s ease;
 
}
 
.footer__back-to-top {
  display: inline-block;
  text-decoration: none; /* Removes underline from the link */
}
 
.back-to-top-image {
  width: 110px; /* Adjust the width */
  height: auto; /* Maintain aspect ratio */
  cursor: pointer; /* Show pointer cursor on hover */
  transition: transform 0.2s; /* Add smooth hover effect */
}
 
.back-to-top-image:hover {
  transform: scale(1.1); /* Slightly enlarge on hover */
}
 
@media (max-width: 768px) {
  .footer {
    padding: 1.5rem 1rem; /* Adjust padding for smaller screens */
    text-align: center; /* Ensure centered alignment */
  }
 
  .footer__container {
    gap: 4rem; /* Reduce gap between elements for compact layout */
  }
 
  .footer__links {
    flex-direction: column; /* Stack links vertically */
    gap: 1.5rem; /* Space between links */
  }
 
  .footer__link {
    font-size: 1rem; /* Smaller font size for better readability */
    line-height: 1.5rem;
  }
 
  .footer__copy {
    font-size: 0.75rem; /* Smaller font size for copyright text */
    margin-top: 1rem; /* Add spacing for separation */
  }
 
  .footer__back-to-top {
    width: 2.5rem; /* Adjust button size */
    height: 2.5rem;
    font-size: 0.875rem; /* Adjust text size */
    bottom: 1rem; /* Position closer to the bottom */
    right: 1rem; /* Position closer to the right */
  }
 
  .back-to-top-image {
    width: 80px; /* Reduce the size of the back-to-top image */
  }
}
 
 
 