@import url('https://fonts.googleapis.com/css2?family=Lato:wght@400;700;900&display=swap');

html {
    scroll-behavior: smooth;
    background-color: black;
    font-family: "Poppins";
  font-weight: 400;
  font-style: normal;

}

.custom-gradienttext {
    background: linear-gradient(90deg, #B07EE3 0%, #2E5BFF 100%);
    -webkit-background-clip: text;
    /* -webkit-text-fill-color: transparent; */
    background-clip: text;
    color: transparent;
}

@keyframes bounceInUpCustom {
    0% {
      opacity: 0;
      transform: translateY(1000px);
    }
    60% {
      opacity: 1;
      transform: translateY(-10px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  .slow-bounce {
    animation: bounceInUpCustom 5s ease-out; /* 5s for very slow animation */
  }
  

  .scrollbar-hidden::-webkit-scrollbar {
    display: none;
}

/* Optional: if you want to support other browsers */
.scrollbar-hidden {
    scrollbar-width: none; /* Firefox */
}

/* Scrollbar styles */
.custom-scrollbar::-webkit-scrollbar {
  width: 8px; /* Width of the scrollbar */
}

.custom-scrollbar::-webkit-scrollbar-track {
  background: #f3f4f6; /* Track color */
  border-radius: 4px;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background: #94abff; /* Scroll thumb color */
  border-radius: 4px;
}

.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background: #2e5bff; /* Scroll thumb hover color */
}

@keyframes pulse {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(1.05);
    opacity: 0.8;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

#parasect {
  animation: pulse 2s ease-in-out infinite;
}


.dots {
  width: 6px;
  height: 5.4px;
  background: #474bff;
  color: #474bff;
  border-radius: 50%;
  box-shadow: 13.4px 0,-26.9px 0;
  animation: dots-h5boszmd 0.5s infinite linear alternate;
}

@keyframes dots-h5boszmd {
  50% {
     box-shadow: 13.4px 0,-13.4px 0;
  }

  100% {
     box-shadow: 26.9px 0,-13.4px 0;
  }
}

.herovideo_section2 {
  position: relative;
  width: 100%;
  height: 900px;
  overflow: hidden;
}

/* Video Background */
.video-bg2 {
  width: 100%;
  height: 110%;
  object-fit: cover;
}

/* Bottom Gradient Overlay */
.herovideo_section2::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100vw;
  height: 70px;
  background: linear-gradient(to top, rgba(0, 0, 0, 1), transparent);
  z-index: 1;
  backdrop-filter: blur(2px);
}
/* Keyframes for wave effect */
@keyframes waveEffect {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Initial state of toolslist container */
.toolslist {
  /* display: flex; */
  opacity: 0;
  animation: waveEffect 0.2s ease-in-out forwards;
}

/* Individual tool list items with delayed animation */
.toolslist1 {
  animation: waveEffect 0.4s ease-in-out forwards;
  /* animation-delay: 0.4s; */
}

.toolslist2 {
  animation: waveEffect 0.6s ease-in-out forwards;
  /* animation-delay: 0.6s; */
}

.toolslist3 {
  animation: waveEffect 0.8s ease-in-out forwards;
  /* animation-delay: 0.8s; */
}

.toolslist4 {
  animation: waveEffect 1s ease-in-out forwards;
  /* animation-delay: 1s; */
}

.toolslist5 {
  animation: waveEffect 1.2s ease-in-out forwards;
  /* animation-delay: 1.2s; */
}

.toolslist6 {
  animation: waveEffect 1.4s ease-in-out forwards;
  /* animation-delay: 1.4s; */
}

.toolslist7 {
  animation: waveEffect 1.6s ease-in-out forwards;
  /* animation-delay: 1.6s; */
}


.herovideo_section3 {
  position: relative;
  width: 100%;
  height: 800px;
  overflow: hidden;
}

/* Bottom Gradient Overlay */
.herovideo_section3::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100vw;
  height: 80px;
  background: linear-gradient(to top, rgba(0, 0, 0, 1), transparent);
  z-index: 1;
  backdrop-filter: blur(2px);
}
