/* Top bar styles */
.top-bar {
  display: flex;
  justify-content: space-between;
  background: linear-gradient(135deg, #4A56E2, #6E7CFF);
  color: white;
  padding: 10px 50px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.logo a {
  background: linear-gradient(90deg, #B07EE3 0%, #2E5BFF 100%);
  -webkit-background-clip: text;
  /* -webkit-text-fill-color: transparent; */
  background-clip: text;
  color: transparent;
  font-size: 2rem;
}

.logo a:hover {
  color: #f0f0f0; /* Slight change on hover for a subtle effect */
}

.top-bar .left-info span, 
.top-bar .right-info span {
  margin-right: 20px;
  display: flex;
  align-items: center;
}

.top-bar .left-info span svg, 
.top-bar .right-info svg {
  margin-right: 5px;
}

.right-info {
  display: flex;
  align-items: center;
}

.right-info svg {
  margin-left: 15px;
  cursor: pointer;
  transition: transform 0.3s ease;
}

.right-info svg:hover {
  transform: scale(1.2);
}

.left-info {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Main Navbar styles */
.main-nav {
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99;
  padding: 30px 50px;
  position: relative;
  border-radius: 10px;
}

.main-nav .logo {
  font-size: 24px;
  font-weight: bold;
  color: white;
  position: absolute;
  left: 50px;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
}

/* Desktop nav-links */
.nav-links {
  display: flex;
  justify-content: center;
  list-style: none;
  gap: 30px;
  margin: 0;
  padding: 0;
}

.nav-links li {
  position: relative;
}

.nav-links li a {
  color: white;
  text-decoration: none;
  font-weight: 500;
  padding: 10px;
  transition: all 0.3s ease;
}

.nav-links li a:hover {
  color: #4A56E2;
  text-shadow: 2px 2px 4px rgba(74, 86, 226, 0.5);
  transform: scale(1.1);
}

/* Hamburger Menu */
.menu-icon {
  display: none;
  font-size: 30px;
  color: white;
  cursor: pointer;
  position: absolute;
  right: 50px;
}

/* Get Started Button */
.get-started-btn {
  color: white;
  padding: 10px 20px;
  border: none;
  width: 150px;
  border-radius: 50px;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  position: absolute;
  right: 50px;
  transition: all 0.3s ease;
  background: rgba( 255, 255, 255, 0.2 );
  backdrop-filter: blur( 13px );
  -webkit-backdrop-filter: blur( 13px );
  border-radius: 30px;
  border: 1px solid rgba( 255, 255, 255, 0.18 );
  /* box-shadow: 0 6px 12px rgba(170, 177, 255, 0.6); */
}

.get-started-btn:hover {
  /* transform: translateY(-2px); */
  box-shadow: 2px 2px 2px rgb(255, 255, 255, 0.3);
}



/* Keyframes for wave effect */
@keyframes waveEffect {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Apply animation to logo */
.logo {
  animation: waveEffect 0.6s ease-in-out forwards;
  animation-delay: 0.2s;
  opacity: 0; /* Start hidden */
}

/* Apply animation to nav-links */
.nav-links li {
  opacity: 0; /* Start hidden */
  animation: waveEffect 0.6s ease-in-out forwards;
}

/* Stagger animation for each nav-link */
.nav-links li:nth-child(1) {
  animation-delay: 0.4s;
}
.nav-links li:nth-child(2) {
  animation-delay: 0.6s;
}
.nav-links li:nth-child(3) {
  animation-delay: 0.8s;
}
.nav-links li:nth-child(4) {
  animation-delay: 1s;
}

/* Apply animation to button */
.get-started-btn {
  animation: waveEffect 0.6s ease-in-out forwards;
  animation-delay: 1.2s;
  opacity: 0; /* Start hidden */
}


/* Mobile View */
@media (max-width: 768px) {
  .main-nav {
    flex-direction: column;
    align-items: flex-start;
    padding: 70px;
    z-index: 9999;
  }

  .main-nav .logo {
    margin-bottom: 70px;
    margin-left: -2.7rem;
    font-size: 17px;
  }

  .logo {
    margin-bottom: 10px;
  }

  .menu-icon {
    display: block;
    margin-left: auto;
    margin-bottom: 60px;
  }

  /* Adjust the nav-links to display properly when the menu is active */
  .nav-links {
    display: none;
    flex-direction: column;
    width: 100%;
    background: #1e2348;
    text-align: center;
    position: absolute;
    top: 100%;
    left: 0;
    margin-top: 10px;
    opacity: 0; /* Start with opacity 0 (invisible) */
    transform: translateY(-20px); /* Start with a slight upward position */
    background: rgba(255, 255, 255, 0.15);
    box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
    backdrop-filter: blur(50px);
    -webkit-backdrop-filter: blur(2px);
    border: 1px solid rgba(255, 255, 255, 0.18);
    transition: none; /* Disable transition to use keyframes */
  }

  /* Keyframes for the slide and fade-in effect */
  @keyframes slideIn {
    0% {
      opacity: 0;
      transform: translateY(-20px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }

  .nav-links.active {
    display: flex;
    animation: slideIn 0.5s ease-in-out forwards; /* Use keyframes animation */
    background: rgba(255, 255, 255, 0.15);
    box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
    backdrop-filter: blur(50px);
    -webkit-backdrop-filter: blur(2px);
    border: 1px solid rgba(255, 255, 255, 0.18);
  }

  .nav-links li {
    margin: 15px 0;
  }

  .get-started-btn {
    display: block;
    width: 150px;
    margin-top: 30px;
    color: white;
    margin-right: 10rem;
    border: none;
    border-radius: 50px;
    padding: 8px 6px;
    font-size: 16px;
    font-weight: bold;
  }

  .get-started-btn:hover {
    /* transform: translateY(-2px); */
    box-shadow: 0 6px 12px rgba(74, 86, 226, 0.5);
  }

  /* Hide all other components except the active one */
  .component {
    display: none; /* Hide all components by default */
  }

  .active-component {
    display: block; /* Only display the active component */
    height: 100vh; /* Fixed height */
    overflow-y: auto; /* Scroll if content overflows */
  }

}
