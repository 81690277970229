/* Hero Section */
.hero {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    margin-top: -5rem;
    overflow: hidden;
    background-color: black;
    box-sizing: border-box;
}



.herovideo_section {
    position: relative;
    width: 100%;
    height: 600px;
    overflow: hidden;
}
  
/* Video Background */
.video-bg {
    width: 100%;
    height: 110%;
    object-fit: cover;
}

/* Bottom Gradient Overlay */
.herovideo_section::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100vw;
    height: 70px;
    background: linear-gradient(to top, rgba(0, 0, 0, 1), transparent);
    z-index: 1;
    backdrop-filter: blur(2px);
}

/* Content Container */
.content {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: #fff;
    z-index: 1; /* Above the video */
}

/* Motto Text */
.motto h1 {
    font-family: Poppins, sans-serif;
    font-size: 6rem;
    font-weight: 600;
    line-height: 1.2;
    color: #ffffff;
    margin: 0;
}

.motto p {
    font-family: Poppins, sans-serif;
    font-size: 1.2rem;
    font-weight: 300;
    line-height: 1.5;
    color: #ffffff;
    margin: 1rem 0;
    max-width: 700px;
}

/* Button Section */
.btn-section {
    display: flex;
    gap: 1rem;
    margin-top: 1.5rem;
    justify-content: center;
}

/* Shared Button Styles */
.btnn-hero {
    /* background-color: rgba(217, 217, 217, 0.27);
    color: white; */
    padding: 10px 20px;
    border: none;
    width: 200px;
    cursor: pointer;
    font-size: 16px;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    transition: all 0.3s ease;
    background: rgba( 255, 255, 255, 0.2 );
backdrop-filter: blur( 13px );
-webkit-backdrop-filter: blur( 13px );
border-radius: 30px;
border: 1px solid rgba( 255, 255, 255, 0.18 );
box-shadow: 0 6px 12px rgba(170, 177, 255, 0.6);
}

.btnn-hero:hover {
    /* transform: translateY(-2px); */
    box-shadow: 0 6px 12px rgba(170, 177, 255, 0.6);
}


#cntbtn {
    border: 1px solid white !important;
}
    
#getstartedbtn {
    border: 1px solid black !important;
   color: black;
   background-color: white !important;
   }

/* Contact Us Button with Icon */
.contact-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: black;
    color: white;
    /* width: 10rem; */
}

.contact-btn:hover {
    /* transform: none; */
    box-shadow: none;
}

.contact-icon {
    width: 20px;
    height: 20px;
}

/* Zoom-In Animation */
@keyframes zoomIn {
    from {
      transform: scale(1.2);
      opacity: 0;
    }
    to {
      transform: scale(1);
      opacity: 1;
    }
  }
  
  /* Add animation to the video section */
  .herovideo_section {
    position: relative;
    width: 100%;
    height: 600px;
    overflow: hidden;
    transition: transform 1s ease-in-out;
  }
  
  .zoom-in-animation {
    animation: zoomIn 1.5s ease forwards;
  }
  
  /* Video Background */
  .video-bg {
    width: 100%;
    height: 110%;
    object-fit: cover;
  }
  
  /* Content Styles Remain Unchanged */
  .content {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: #fff;
    z-index: 1;
  }

  /* Bottom-to-top animation */
@keyframes bottomToTop {
    from {
        transform: translateY(50px);
        opacity: 0;
    }
    to {
        transform: translateY(0);
        opacity: 1;
    }
}

/* Initially hide the content */
.content {
    opacity: 0;
    transform: translateY(50px);
    transition: all 1s ease-in-out;
}

/* Apply animation when loaded */
.content.animate-content {
    animation: bottomToTop 1s ease forwards;
}

  

/* Responsive Styles */
@media (max-width: 768px) {
    .motto h1 {
        font-size: 2rem;
    }

    .motto p {
        font-size: 1rem;
    }

    .btn-section {
        flex-direction: column;
        gap: 1rem;
    }

    .btnn-hero {
        width: 100%;
    }
}
