.section_all {
  position: relative;
  background-color: #000;
  color: #fff;
  padding: 10rem 0rem 5rem 0rem;
}

.section_all::after {
  content: '';
  position: absolute;
  top: 56rem;
  left: 0;
  width: 100%;
  height: 50px;
  background: rgb(0 0 0);
  filter: blur(12px);
  z-index: 1;
}


/* Animation for when elements come into view */
@keyframes bottomToTop {
  from {
    transform: translateY(50px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

/* Apply animation to elements when observed */
.animate {
  animation: bottomToTop 1.2s ease-out forwards; /* Updated to 1.2s for smoother animation */
}

/* Initially hide elements */
.hidden21 {
  opacity: 0;
  transform: translateY(50px);
  transition: opacity 1.2s ease-out, transform 1.2s ease-out; /* Ensure transition time matches animation */
}

.About-container {
  max-width: 1200px;
  margin: 0 auto;
}

.sub {
  margin-top: 0rem !important;
}

.content_subtitle {
  font-family: Poppins;
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  margin-top: 1.7rem;
}

.section_title_all {
  text-align: center;
  margin-bottom: 40px;
}

.section_title {
  font-family: Poppins;
  font-size: 64px;
  font-weight: 600;
  color: #fff;
  line-height: 96px;
}

.content_section {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  margin-top: 5rem;
}

.left_content,
.right_content {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 60px;
}

.center_content {
  flex: 1.5;
  display: flex;
  justify-content: center;
  align-items: center;
}

.video_content {
  max-width: 630px;
}

.about_title {
  font-size: 1.2rem;
  font-weight: bold;
  color: #fff;
  margin-bottom: 10px;
}

.abouts_title {
  white-space: nowrap; /* Prevent line break */
  overflow: hidden; /* Optional: Hide overflowing text */
  text-overflow: ellipsis; /* Optional: Add ellipsis if text overflows */
  font-size: 16px; /* Adjust text size if needed */
}

.contents_boxes {
  align-items: center;
  margin: 0 10px;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.iconsimg {
  width: 52px;
  height: 52px;
}

/* Media Queries for Mobile */
@media (max-width: 768px) {
  .content_section {
    flex-direction: column;
    align-items: center;
    gap: 30px;
  }

  .left_content,
  .right_content {
    flex: unset;
    width: 100%;
    gap: 20px;
    text-align: center;
  }

  .center_content {
    flex: unset;
    width: 100%;
    margin-top: 1rem;
  }

  .video_content {
    max-width: 100%;
    padding: 0 10px;
  }

  .section_title {
    font-size: 2rem;
    line-height: 1.5;
  }

  .about_title {
    font-size: 1rem;
    margin-bottom: 5px;
  }

  .iconsimg {
    width: 40px;
    height: 40px;
  }

  .contents_boxes {
    margin: 0;
    gap: 10px;
  }

  .content_subtitle {
    font-size: 16px;
    line-height: 1.4;
    margin-top: 1rem;
  }
}
