
/* Add this CSS to your global styles or a specific CSS file */
.custom-scrollbar {
    scrollbar-width: thin; /* For Firefox */
    scrollbar-color: #3b82f6 #e5e7eb; /* For Firefox */
}

.custom-scrollbar::-webkit-scrollbar {
    width: 8px; /* Width of the scrollbar */
}

.custom-scrollbar::-webkit-scrollbar-track {
    background: #e5e7eb; /* Track color */
}

.custom-scrollbar::-webkit-scrollbar-thumb {
    background-color: #3b82f6; /* Thumb color */
    border-radius: 10px; /* Rounded corners */
}

.custom-scrollbar::-webkit-scrollbar-thumb:hover {
    background-color: darkblue; /* Thumb color on hover */
}
