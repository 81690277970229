css new 
 
.faq-container {
  background-color: black;
  padding: 2rem;
  position: relative;
  border-radius: 2rem;
  overflow: visible;
}
 
.faq-heading {
  text-align: center;
  margin-bottom: 20px;
}
 
.faq-heading h2 {
  color: white;
  font-size: 2rem;
  font-weight: semibold;
}
 
.faq-divider {
  border-bottom: 3px solid #2E5BFF;
  width: 180px;
  margin: 20px auto;
  margin-bottom: 3rem;
}
 
.faq-item {
  padding: 26px;
  color: white;
  position: relative;
  background: rgba(255, 255, 255, 0.15);
  backdrop-filter: blur(2px);
  -webkit-backdrop-filter: blur(2px);
  cursor: pointer;
}
 
.faq-item.rounded-t-xl {
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
}
 
.faq-item.rounded-b-xl {
  border-bottom-left-radius: 1rem;
  border-bottom-right-radius: 1rem;
}
 
.faq-item.open {
  color: black;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.3);
}
 
.faq-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: semibold;
  font-size: 1.25rem;
}
 
.faq-text-container {
  max-height: 0;
  overflow: hidden;
  padding-top: 10px;
  transition: max-height 0.3s ease, background 0.3s ease;
  background: transparent;
}
 
.faq-item.open .faq-text-container {
  max-height: 1000px;
  background: white;
  padding: 16px;
  border-radius: 8px;
  margin-top: 1rem;
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.2);
}
 
.faq-text {
  margin: 0;
  color: black;
  font-size: 1rem;
}
 
.faq-arrow {
  width: 15px;
  height: 10px;
  transition: transform 0.3s;
}
 
.faq-arrow.rotate {
  transform: rotate(180deg);
}
 
 