/* Main Loading Screen Container */
.loading-screen {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: black;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
    text-align: center;
    z-index: 9999;
}

/* Stage 1 - Title */
.loading-title {
    font-family: 'Poppins', sans-serif;
    font-size: 128px;
    font-weight: 600;
    line-height: 192px;
    color: white;
    animation: fadeIn 2s ease-in-out forwards;
}

/* Stage 2 - Subtitle and Image */
.loading-subtitle {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
}

/* Animated text */
.animated-text {
    font-family: Poppins;
    font-size: 64px;
    font-weight: 600;
    line-height: 96px;
    font-weight: bold;
    margin-bottom: 20px;
    animation: moveUp 1.5s ease-in-out forwards;
    color: white;
}

/* Image styling */
.stage2-image {
    position: absolute;
    top: 50%; /* Center vertically */
    transform: translate(-50%, -50%); /* Perfect centering */
    width: 55%;
    height: auto;
    object-fit: cover;
    z-index: 1;
    transition: transform 3s ease-out, margin-top 3s ease-out; /* Smooth transition for both margin and rotation */
}

/* Stage 3 - Final Stage */
.loading-final-stage {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
}

.loading-tagline {
    font-family: Poppins;
    font-size: 64px;
    font-weight: 600;
    line-height: 96px;
    font-weight: bold;
    margin-bottom: 200px;
    animation: moveUp 1.5s ease-in-out forwards;
    color: white;
}

/* Animations */
@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes moveUp {
    from {
        transform: translateY(50px);
        opacity: 0;
    }

    to {
        transform: translateY(0);
        opacity: 1;
    }
}
