/* Popup overlay */
.popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    animation: fadeIn 0.5s ease-out; /* Fade-in animation */
}

/* Popup form styles */
.popup-form {
    padding: 2rem;
    border-radius: 20px;
    max-width: 400px;
    width: 90%;
    text-align: center;
    position: relative; /* To position the close icon inside the form */
    transform: translateY(20px); /* Initially off-screen */
    animation: slideUp 0.5s ease-out forwards; /* Slide-up animation */
    background: rgba( 255, 255, 255, 0.15 );
   box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
   backdrop-filter: blur( 2px );
   -webkit-backdrop-filter: blur( 2px );
    border: 1px solid rgba( 255, 255, 255, 0.18 );
}

.popup-form h2 {
    margin-bottom: 1.5rem;
    color: #ffffff;
}

.input-field {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
    background: #f9f9f9;
    padding: 0.5rem;
    border-radius: 30px;
}

.input-field img {
    width: 20px;
    margin-right: 0.5rem;
    margin-left: 0.6rem;
}

.input-field input {
    flex: 1;
    border: none;
    outline: none;
    background: transparent;
    padding: 0.5rem;
    font-size: 1rem;
}

.submit-btn {
    background-color: #2E5BFF;
    color: white;
    border: none;
    padding: 0.7rem 1.5rem;
    border-radius: 30px;
    cursor: pointer;
    font-size: 1rem;
    transition: background-color 0.3s;
    width: 196px;
    height: 49px;
}

.submit-btn:hover {
    background-color: #2445c8;
}

/* Close icon in the top-right corner */
.close-icon {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 20px;
    cursor: pointer;
}

/* Animations */
@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes slideUp {
    0% {
        opacity: 0;
        transform: translateY(20px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}
